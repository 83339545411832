// General
$body-background: #ffffff;
$logo-color: #000;

// Font-family
$font-sans: 'Inter';
$font-light: 300;
$font-regular: 400;
$font-bold: 700;
$font-semi-bold: 600;
$font-eana-icon: 'eanaicons';

// Color's
$general-text-color: #000;
$page-bg-color: #fff;
$success-color: #06842e;
$indicator-icon-color: #fff;
$failure-color: #ee1148;
$common-bdr-color: #eee;
$disable-color: #aeaeae;

// Fonts size
$base-font-size: 14px;
$base-line-heigth: 20px;
$input-font-size: 12px;

// link
$link-color: #1f75d1;

// Button
$btn-primary-bg: #2a3c63;
$btn-primary-color: #fff;
$btn-secondary-color: #2a3c63;
$btn-secondary-bg: #fff;
$btn-disabled-bg: #c2c2c2;
$btn-hover-bg: #297fca;

// Checkbo x
$checkbox-color: #06842e;
$checkbox-tick-color: #fff;
// Field card
$card-bdr-color: #c2c2c2;

// Login page
$bar-bg: linear-gradient(180deg, #88ff76 0%, #3ec3a5 24.84%, #19a5bc 66.67%, #19a5bc 100%);
$bar-bg-mbl: linear-gradient(90deg, #88ff76 0%, #3ec3a5 24.84%, #19a5bc 66.67%, #19a5bc 100%);
$signup-bg: #CCE8E9;

// Left Menu
$left-menu-bg: #f5f5f5;
$left-menu-active-bdr: #209fd5;
$left-menu-active-bg: #fff;
$left-menu-over-bg: #bce2f2;
$left-menu-font-size: 12px;
$left-menu-line-height: 17px;

// Forms
$lbl-font-size: 12px;
$lbl-font-weight: 300;
$input-font-weight: 600;
$input-font-size: 12px;
$input-bdr-color: #ccc;

// Header
$header-bg: #fff;
$breadcrumb-color: #3F4949;
$breadcrumb-bdr-color: #eee;

// Field Card
$card-border: #cccccc;
$card-leftline-bg: #aeaeae;
$card-new-icon: #06842e;
$card-plus-icon: #ffffff;

// Edit Fields
$edit-fields-bg: #f5f5f5;

// Add Farm
$add-farm-button-color: #E6E9E8;

// Manage users
$user-card-border: #aeaeae;
$user-card-hover: #E6E9E8;
$user-trash-bg: #ee1148;

// Tasks
$task-create-bg: #fafafa;
$task-card-border: #06842e;
$light-box-border: #cccccc;
$light-box-bg-white: #ffffff;
$light-box-bg: #e8f5fa;
$calendar-border: #cccccc;
$calendar-bg: #ffffff;
$seperator-bg: #bce2f2;
$input-bg: #ffffff;
$input-border: #cccccc;

// Manage assets
$seperator-bg: #fafafa;

// Footer
$footer-bg: #fafafa;
$footer-contact-details-bg: #eeeeee;
$footer-italic-color: #aeaeae;
$footer-link-hover: #E6E9E8;

//Mobile width
$mobile-screen: 640px;
$tablet-screen: 1024px;
$laptop-screen: 1350px;
$tab-screen-min: 768px;

$terms-heading-color: #2a3c63;
//dropdown
$dropdown-bg: #fff;
$dropdown-bdr: #ccc;
$dropdown-item-bg: #fafafa;
$dropdown-item-bdr: #eee;
$dropdown-item-active-bg: #CCE8E9;
// Loader
$loader-bg: #ffda9d;

$tbl-bdr: #696969;
$tbl-th-bg: #eee;
$tbl-bg: #fafafa;

// task list
$task-list-bdr: #ccc;
$task-grey-indicator: #aeaeae;
$task-green-indicator: #06842e;
$task-ongoing-indicator: #ee1148;
$task-hover: #f5f5f5;
$task-bg: #fff;
$task-patch-bg: #e8f5fa;

// maps
$map-icon-bg-color: #ffffff;
$map-gps-bg-color: #E6E9E8;
$map-gps-bg-color-hover: #E6E9E8;
$map-gps-bg-color-active: #E6E9E8;
$map-fullscreen-bg-color: #f8f8f8;

$header-farm-bg: #bce2f2;
$menu-bar-icon-bg: #E6E9E8;
$menu-bar-icon-bg-hover: #fff;
$farm-icon-color: #231f20;
$navbar-break: 1024px;

// z-index

$leaflet-btm-zindex: 2;
$gps-zindex: 3;
$draw-boundary-zindex: 5;
$nav-bar-zindex: 9;
$header-farm-zindex: 7;
$page-loader-zindex: 103;
$loader-zindex: 101;
$done-zindex: 2;

// KPI
$kpi-bdr-color: #979797;
$kpi-bdr-l-color: #aeaeae;
$kpi-bdr-l-hcolor: #E6E9E8;
$kpi-bdr-user: #f5f5f5;

$white: #fff;

$secondary: #E6E9E8;
$secondary-shade-dark: #E6E9E8;
$secondary-shade-light: #bce2f2;
$secondary-shade-medium: #E6E9E8;

$positive: #06842e;
$positive-shade-light: #b8e986;

$negative: #ee1148;
$negative-dark: #c20030;
$negative-shade-light: #f2547b;

$gray-light: #eee;

$app-shade-light: #e0efed;

$app-dark: #2c9286;